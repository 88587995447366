.accontiner{
    padding: 10px;
    background-color: white;
    width: 100%;
    height: 100vh;
}
.accountsetting{
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.header{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
}
.header h2{
    font-size: 20px;
    color: chocolate;
}
.header img{
    height: 90px;
    width: 90px;
}
.content-continer{
    display: flex;
    width:60% ;
   flex-direction: column;
   border-bottom: solid;
   border-bottom-width: 00.5px;
   border-bottom-color: rgb(139, 139, 139);
}
.name{
    display: flex;
    width: 100%;
    flex-direction: row;
    font-family: var(--font);
}
.email{
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    font-family: var(--font);

}
.name .lable{
     display: flex;
     width: 40%;
    justify-content: flex-end;
    font-family: var(--font);
}
.name .value{
    display: flex;
    width: 60%;
    margin-left: 10px;
    color:#566573 ;
    font-family: var(--font);

}
.email .lable{
    display: flex;
    width: 40%;
   justify-content: flex-end;
   font-family: var(--font);

}
.email .value{
   display: flex;
   width: 60%;
   color:#566573 ;
   margin-left: 10px;
   font-family: var(--font);

}
.form-header {
    margin-top: 15px;
    color: chocolate;
}
.form-header h2{
    font-size: 17px;
    color: chocolate;
}
.form{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction:column;
}
.field{
    display: flex;
    width: 60%;
    margin-top: 10px;
   flex-direction: row;
}
.field h4{
    display: flex;
    width: 40%;
    height: 40px;
    font-size: 15px;
    justify-content: flex-end;
    align-items: center;
}
.input{
    margin-left: 10px;
    display: flex;
    width: 60%;
    height: 40px;
}
.btn-continer{
    display: flex;
    width: 60%;
    height: 40px;
    margin-top: 20px;
    align-items: flex-end;
    justify-content: flex-end;
   
}
.btn{
    display: flex;
    width: 25%;
    height: 40px;
    justify-content: center;
    align-items: center;   
    background-color: chocolate;
    color: white;
}
input[type=password]{
    outline: none; 
    border: 1px solid #a7a8a8;

}
input[type=password]:focus{
  outline: none; 
 border: 1px solid chocolate;
}
.form-continer{
    width: 100%;
}
.form-header h2{
    font-family: var(--font);
}

@media screen and (max-width: 630px) {
    .content-continer{
        width:100% ;
    }
    .field{
        width:100% ;
    }
    .btn-continer{
        width: 100%;
        align-items:center;
        justify-content:center;
    }
    .btn{
        width: 50%;
    }
    .field h4{
        font-size: 10px;
    }
    .input{
        margin-left: 10px;
        display: flex;
        width: 60%;
        height: 30px;
    }
    .btn{
        height: 30px;
    }
    .header h2{
        font-size: 15px;
    }
   
    .form-header h2{
        font-size: 15px;
    }
   
  }
 
