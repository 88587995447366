.descPage
{
    padding: 10px;
   
}
#abstracthtml p,a,div,span{
    font-family: var(--font);
}
#abstracthtml  h1,h2,h3,h4,h5,h6,h7  {
    font-family: var(--font);
    letter-spacing: 1px;
    color: #000;
}
#articlehtml  h1,h2,h3,h4,h5,h6,h7  {
    font-family: var(--font);
    letter-spacing: 1px;
    color: #000;
}
#abstracthtml img{
    max-width: 100%;
    display: block;
    margin: auto;
}
#articlehtml p,a,div,span ul{
    font-family: var(--font);

}
#articlehtml ol, ul, dl {
    font-family: var(--font);

}
#articlehtml img{
    max-width: 100%;
    display: block;
    margin: auto;
}
.img-fullscreenIcon{
    display: none;
}
.fullscreenIcon{
    display: none;
}
li {
    font-family: var(--font);

    margin-left: 20px;
    list-style-type:disc;
 }


.descImg
{
    text-align: center;
    width: 100%;
    height: 450px;
    /* margin:0 auto; */
    /* padding:10px 20px; */
    background-color: #EEFBF9;


}
table{
    overflow-x:auto;
    display: block;

  }
  table, th, td {
    border: 0.5px solid rgb(97, 95, 95);
    
  }
  td { 
    padding: 10px;
}
th { 
    padding: 10px;
    font-weight: bold;
    background-color: #ddd;
}
thead td{
    padding: 10px;
    font-weight: bold;
    background-color: #ddd; 
}
.tableCaption div{
    font-weight: bold;

}
.imgeVideoCaption div{
    font-weight: bold;

}
.img {
    border: 1px solid #E5E5E5;
    padding: 10px;
    width: 95%;
    position: relative;
}
.articlecontiner{
    position: relative;
    display: block;
}
.ref-content.cell, .ref-label.cell {
    display: table-cell;
    font-family: var(--font);
    padding-top: 10px;
  
}
.ref-content .name{
    display: inline;
    font-family: var(--font);

}








 

.descTitle
{
    font-weight: 600;
    font-size: 18px;
    color: #9099e0;
    margin-top: 5px;
   
}
.descAuthor
{
    font-weight: 600;
    white-space: normal;
    font-size: 14px;
    margin-top: 7px;
    
}
.descPub
{
    color: #999999;
    font-weight: 600;
    font-size: 14px;
    margin-top: 0px;
    
}
.buttonList
{
    padding-right: 3px;
    padding-bottom: 10px;
}

.descHead
{
    
    font-weight: bold;
    /* margin-top: 20px; */
}

.description
{
    margin-top: 5px;
    font-weight: 400;
    font-size: 14px;
    text-align:justify
    
}
.articledesc{
    font-family: var(--font);
    float:"none";
    letter-spacing:1px;
    font-size: 14px;
    color:#273746
}
.articledescheade{
    font-family: var(--font);
    font-size:15px;
    font-weight: 500;
    letter-spacing:1px;
    color: #000;
}
.articlelistheader{
    font-family: var(--font);
    padding:7px;
    font-size:15px;
    letter-spacing:1px;
    color: #000;

}
.btntext{
    color: #ffff;
}
Button{
    font-family: var(--font);
}

.coverbg{
    background-repeat:no-repeat;
    background-size: cover;
    position: relative;
    height: 40%;
    width: 100%;
    filter: blur(3px);
    background-color: rgb(218, 218, 218);
    animation:example 4s infinite;

}
.dcover_cntiner{
    width: 80%;
    height: 100%;
    background-color: rgb(199, 228, 255);
    animation:example 10s infinite;
}
.dcover{
    width: 70%;
    background-color: #EEFBF9;
    box-shadow: 4px 6px 5px -5px rgba(0,0,0,0.75);
    -webkit-box-shadow: 4px 6px 5px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: 4px 6px 5px -5px rgba(0,0,0,0.75);
  }


.continer{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #fff;
    scroll-behavior: smooth;
 }
 .articlecontiner{
    width: 100%;
    display: flex;
    height: 93%;
    overflow: scroll;
    flex-direction: column;
    scroll-behavior: smooth;
}
.articleheadername{
    /* display: flex; */
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
    color: #585757;
    position: relative;
    margin-left: 3px;
    line-height: 21px;
    height: 21px;
    font-family: var(--font);
    font-weight: bold;
    color: #000;
    letter-spacing: 1px;
    font-size: 15px;

    
}
.articleheadername::after{
    content: "";
    position: absolute;
    bottom: 0px;
    right: 0px;
    height: 21px;
    width: 40%;
    background-image: linear-gradient(to right, rgba(255,0,0,0), rgb(255, 255, 255));
}
.BHead{
    font-size: 15px;
    color: #000;
}

@keyframes example {
    0%   {background-color: rgb(221, 217, 217);}
    25%  {background-color: rgb(177, 177, 174);}
    50%  {background-color: rgb(213, 213, 216);}
    100% {background-color: rgb(168, 172, 168);}
  }