*{
    font-family: 'Lato';
}

.tabbar{
    display: flex;
    width: 100%;
    height: 40px;
    align-items:center;
    justify-content:space-around;
    background-color: #fff;
    margin-top: 10px;
   }
   .tabbar_item{
    display: flex;
    width: 30%;
    height: 40px;
    align-items:center;
    justify-content: center;
    flex-direction: column;
    font-size: 15px;
    letter-spacing: 1px;
    font-family: var(--font);
   }
   .imges{
    margin-left: 5px;
    background-color:#EEFBF9;
    
 }

 .bookcard{
     height: 100vh;
     width: 100%;
     overflow: scroll;
     align-items: center;
     scroll-behavior: smooth;
     justify-content: center;
 }
 .sbookcard{
    position: relative;
    overflow: hidden;
   
}
 .bookcard_continer{
     position: relative;
     display: flex;
     flex-direction: row;
     background-color: #fff;
     box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
     border-radius: 5px;
     margin-top: 10px;
     margin-left: 7px;
     margin-right: 7px;
     margin-bottom: 5px;
    
 }
 .bookcover_continer{
    height: 170px; 
    width:140px ;
    display: flex; 
    align-items: center;
    justify-content: center;
 }

 .bookcover{
     width: 140px;
     height: 170px;
     border-radius: 5px;
     display: flex;
     align-items: center;
     justify-content: center;
     background-color: #EEFBF9;
 }
 
 .skeletonbookcover{
    width: 140px;
    height: 170px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
 .bookcontent{
     width: 100%;
     display: flex;
    flex-direction: column;
    margin-top: 7px;
    margin-left: 5px;
    margin-right: 5px;
    overflow: hidden
 }
 .booktitle{
     font-family: var(--font);
     font-size: 15;
     letter-spacing: 1px;
     color:#363535;
 }
 .bookisbn{
     font-family: var(--font);
     margin-top: 2px;
    font-weight:lighter;
    font-size: 12;
    color:#273746 ;
 }
 .bookisbn span{
     font-family: var(--font);
    font-size: 11;
    color:#808080 ;

 }
 .learnmore{
     font-family: var(--font);
     color: #33A3F4;
     letter-spacing: 1px;
     font-size: 9px;
 }
 .deleteicon{
     font-size: 20px;
     margin-left: 10px;
     margin-top: 10px;
     color: rgb(180, 6, 6);
 }
 .loadingicon{
    font-size: 20px;
    margin-left: 10px;
    color: rgb(7, 47, 224);
 }

 .readbtncontiner{
     display: flex;
     width: 100%;
     align-items: center;
 }