.headerbar{
    display: flex;
    height: 7%;
    width: 100%;
    overflow: scroll;
    align-items: center;
    justify-content: left;
    flex-direction:row;
    font-family: var(--font);

    /* background-color: black; */
 }
 .gobackicon{
     display: flex; 
     height: 100%;
     width: 10%;
     align-items:center;
     justify-content: center;
 
 }
 .bartitel{
    font-size:17px;
    letter-spacing: 1px;
    font-weight: bold;
    font-family: var(--font);
    width: 100%;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     display: inline-block;
 }
 .bartitelcontiner{
     display: flex;
     height: 100%;
     width: 90%;
     align-items: center;
     justify-content: center;
 }
 .booklisttitel{
     letter-spacing: 1px;
     font-size:18px;
     text-transform:uppercase;

     
 }
 .pageheadertitel{
     margin-left: 0px;
     font-size:17px;
     letter-spacing: 1px;
     font-weight: bold;
     display: inline-block;
     text-transform:capitalize;
     text-overflow: ellipsis;
     white-space: nowrap;

 }
 