



.journalLis{
    align-items:center;
    padding: 5px;
}
.journalCover{
    margin: 10px;
    padding: 10px;
    width: auto;
    height: 240px;
    background-size: 100% 100%;
}

.descImg
{
    text-align: center;
    width: 100%;
  
    margin:0 auto;
    padding:10px 20px;


}
.descTitle
{
    font-weight: 600;
    font-size: 18px;
    color: #9099e0;
    margin-top: 5px;
   
}

.descHead
{
    
    font-weight: bold;
    margin-top: 20px;
}
.description
{
   margin-top: 5px;
    font-weight: 400;
    font-size: 14px;
    
}
.descPage
{
    padding: 10px;
    margin-top: 40px;
}

.journalCover
{
    box-shadow: 4px 6px 5px -5px rgba(0,0,0,0.75);
    -webkit-box-shadow: 4px 6px 5px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: 4px 6px 5px -5px rgba(0,0,0,0.75);
}
.continer{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #fff;
    scroll-behavior: smooth;
 }
 .listcontiner{
    display: flex;
    height: 90%;
    overflow: scroll;
    scroll-behavior: smooth;
}
.category-titel{
    margin-left: 10px;
    font-family: var(--font);
    letter-spacing: 1px;
    margin-top: 5px;
    font-size: 16px;
}
.nodata{   
    display: flex;
     height: 93%;
     background-color: #fff;
     flex-direction: column;
     align-items: center;
     justify-content: center;  
     color: rgb(201, 196, 196); 
     text-align:center; 
     margin: 30px;
}
.articlename{
   font-family: var(--font);
   letter-spacing:1px;
   font-size:14px;
   color:#273746;
}
.articledoi{
    font-family: var(--font);
    letter-spacing:1px;
    font-size:12px;
    padding-top:3px;
    color:#808080
 }
 .articleauthorname{
    font-family: var(--font);
    letter-spacing:1px;
    font-size:12px;
    padding-top:3px;
    color:#808080
 }

.moreloading{
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(59, 130, 236);
    font-size: 15px;
    font-family: var(--font);
}



