:root {
    --font:'Poppins', sans-serif;;
    --white: #ffffff;
  }
  *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        /* background-color: #ffffff; */
        
    }
    body{
        background-color: #fff;
    }
    .pageheadertitle{
        font-size: 17px;
        font-weight: 500;
        font-family: var(--font);
        letter-spacing: 1px;
        color: #000;
    }
    /* ::-webkit-scrollbar {
        width: 0; 
        background: transparent;  
    } */
    /* .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn { */
        /* color: #cab8ae; */
        /* font-weight: 500; */
    /* } */
    /* .ant-tabs-ink-bar { */
        /* position: absolute; */
        /* background: #f67225; */
        /* pointer-events: none; */
    /* } */

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #f67225;
        font-family: var(--font);
        letter-spacing: 1px;
        font-weight: 500;
    }
    .ant-tabs-ink-bar {
        position: absolute;
        pointer-events: none;
    }
    .ant-tabs-tab {
        color: #a5a4a3;

        font-family: var(--font);
        letter-spacing: 1px;
        font-weight: 500;      }
      
    
   